:root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    /**/
    margin: 0px;
    padding: 0px;
}

* {
    font-family: "Roboto";
}

body{
    display: flex;
    flex: 1;
    /**/
    margin: 0px;
    padding: 0px;
}

#root{
    display: flex;
    flex: 1;
}

h3{
    margin: 0px;
}