.form{
    display: flex;
    flex: 1;
    flex-direction: column;
    /**/
    gap: 5px;
    width: 100%;
    max-width: 400px;
    justify-content: center;
    align-self: center;
}

.blockUp {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.blockDown {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}